import cn from 'classnames'
import * as React from 'react'
import styles from './Table.module.scss'

export type RootProps = React.ComponentPropsWithoutRef<'table'> & {
  tableClassNames?: string
}

export const Root = ({
  children,
  className,
  tableClassNames,
  ...restProps
}: RootProps) => (
  <div className={cn(styles.tableWrapper, className)}>
    <table className={cn(styles.table, tableClassNames)} {...restProps}>
      {children}
    </table>
  </div>
)
